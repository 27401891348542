export const standards = [
    {
        id: 'stand_1',
        title: '1',
        description: 'Las mejores telas',
    },
    {
        id: 'stand_2',
        title: '2',
        description: 'Estándares de calidad',
    },
    {
        id: 'stand_3',
        title: '3',
        description: 'Materiales certificados',
    },
];
