export const otherLocations = [
    {
        id: 'loc_gyq',
        locations: 'Coral la Alborada y Coral Carlos Julio en Guayaquil.',
    },
    {
        id: 'loc_cuenca',
        locations:
            'Coral Centro, Mall del Rio y Hipermercado Coral Sucre en Cuenca.',
    },
    {
        id: 'loc_coral_multi',
        locations:
            'Coral Hipermercados en Manta, Samborondon, Magdalena Quito y Santo Domingo.',
    },
];
